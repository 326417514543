<template>
  <div>
    <b-row v-if="summary">
      <b-col
          lg="4"
          sm="6"
      >
        <statistic-card-horizontal
            icon="HomeIcon"
            :statistic="'৳ '+summary.total_business_onboarded"
            statistic-title="Total Business Onboarded"
        />
      </b-col>
      <!--      <b-col-->
      <!--          lg="4"-->
      <!--          sm="6"-->
      <!--      >-->
      <!--        <statistic-card-horizontal-->
      <!--            icon="PercentIcon"-->
      <!--            color="success"-->
      <!--            :statistic="'৳ '+summary.total_commission"-->

      <!--            statistic-title="Total Commission"-->
      <!--        />-->
      <!--      </b-col>-->
      <b-col
          lg="4"
          sm="6"
      >
        <statistic-card-horizontal
            icon="DollarSignIcon"
            color="danger"
            :statistic="'৳ '+summary.total_commission"
            statistic-title="Total Earning"
        />

      </b-col>

    </b-row>
    <b-row>
      <b-col
          lg="12"
      >
        <b-card title="Finance Book">

          <!-- search input -->
          <div class="custom-search d-flex justify-content-between">
            <b-form-group class="justify-content-start">
              <div class="d-flex align-items-center">
                <b-col md="12">
                  <v-select
                      id="fh-select"
                      v-model=" user_id"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="affiliateSimple"
                      :reduce="name=>name.user_id"
                      label="name"
                      @input="updateProduct"
                      class="mr-50 "
                      style="min-width:300px"
                  />
                </b-col>
                <b-col>
                  <b-button @click="resetTable">
                    Reset
                  </b-button>

                </b-col>


              </div>
            </b-form-group>
            <b-form-group class="justify-content-end">
              <div class="d-flex align-items-center">
                <label class="mr-1">Search</label>
                <b-form-input
                    v-model="searchTerm"
                    placeholder="Search"
                    type="text"
                    class="d-inline-block"
                />
              </div>
            </b-form-group>
          </div>

          <!-- table -->
          <vue-good-table
              :columns="columns"
              :rows="rows"
              :rtl="direction"
              :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
              :pagination-options="{
        enabled: true,
        perPage:pageLength

      }"
              styleClass="vgt-table striped condensed no-outline"
              v-if="finance"
          >

            <template
                slot="table-row"
                slot-scope="props"
            >

              <!-- Column: Name -->


              <!-- Column: Action -->

              <span v-if="props.column.field === 'subscription_package'">
                 <span class="font-weight-bolder"> {{ props.row.subscription_package }}</span><br>
                  <small>
                    <span class="font-weight-bolder">Price:</span> ৳ {{ props.row.active_price }} <br>
                    <span class="font-weight-bolder" v-if="props.row.on_sale">ON-SALE</span>  <span
                      class="font-weight-bolder" v-else="props.row.on_sale">REGULAR</span>
                  </small>
              </span>
              <span v-else-if="props.column.field === 'commission_amount'">
              <small>

               <span class="font-weight-bolder">Amount:</span> ৳  {{ round(props.row.commission_amount,2) }}<br>
                <span class="font-weight-bolder">Percentage:</span> {{ props.row.commission_ratio }}  %<br>
               <span class="font-weight-bolder">Type: </span>{{ props.row.affiliate_finance_type }}<br>
                </small>
              </span>
              <span v-else-if="props.column.field === 'coupon_code'">
                 <small>
                       <span class="font-weight-bolder">Code:</span> {{ props.row.coupon_code }}  %<br>
                       <span class="font-weight-bolder">Value:</span> {{ props.row.coupon_value }}%<br>
                       <span class="font-weight-bolder">Amount: </span> ৳ {{ props.row.coupon_amount }}<br>
                        </small>
                      </span>

              <span v-else-if="props.column.field === 'seller_receivable'">
          <p>৳ {{ props.row.seller_receivable }}</p>
        </span>
              <span v-else-if="props.column.field === 'index'">
         <span>{{ (props.row.originalIndex + 1) + (pageNumber > 1 ? ((pageNumber - 1) * 10) : 0) }}</span>
        </span>

              <!-- Column: Common -->
              <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
            </template>

            <!-- pagination -->
            <template
                slot="pagination-bottom"
                slot-scope="props"
            >
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <!--            <span class="text-nowrap ">-->
                  <!--              Showing 1 to-->
                  <!--            </span>-->
                  <!--            <b-form-select-->
                  <!--                v-model="pageLength"-->
                  <!--                :options="['3','5','10']"-->
                  <!--                class="mx-1"-->
                  <!--                @input="(value)=>props.perPageChanged({currentPerPage:value})"-->
                  <!--            />-->
                  <span class="text-nowrap"> Total {{ totalCount }} entries </span>
                </div>
                <div>
                  <b-pagination
                      :value="1"
                      :total-rows="totalCount"
                      :per-page="pageLength"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-0"
                      @change="handleChangePage"
                  >
                    <template #prev-text>
                      <feather-icon
                          icon="ChevronLeftIcon"
                          size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                          icon="ChevronRightIcon"
                          size="18"
                      />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>


        </b-card>
      </b-col>
    </b-row>
  </div>


</template>

<script>

import {
  BAvatar, BButton, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, BCard,
  BModal, VBModal, BCardText, BRow, BCol,
} from 'bootstrap-vue'
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
import {VueGoodTable} from 'vue-good-table'
import store from '@/store/index'
import vSelect from "vue-select"
import Ripple from "vue-ripple-directive";
import {mapState} from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {round} from "echarts/src/util/number";

export default {
  components: {
    VueGoodTable,
    BAvatar,
    BCard,
    BBadge,
    BButton,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BModal,
    BRow,
    BCol,
    StatisticCardHorizontal,
    vSelect,
    BCardText
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      nameState: null,
      addNewMode: false,
      popupActive: true,
      modify: {
        product: null,
        sale_price: null,
      },
      item_id: null,
      user_id: null,
      pageNumber: 1,
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: '#',
          field: 'index',
          type: 'number',
          tdClass: 'text-center',
          thClass: 'text-center'
        },
        {
          label: 'Affiliate',
          field: 'affiliate',
          tdClass: 'text-center',
          thClass: 'text-center',
          sortable: false
        },
        {
          label: 'Business',
          field: 'business',
          tdClass: 'text-center',
          thClass: 'text-center',
          sortable: false
        },
        {
          label: 'Package',
          field: 'subscription_package',
          tdClass: 'text-start',
          thClass: 'text-center'
        },
        {
          label: 'Commission',
          field: 'commission_amount',
          tdClass: 'text-start',
          thClass:'text-center'
        },
        {
          label: 'Coupon',
          field: 'coupon_code',
          tdClass: 'text-center',
          thClass:'text-center'
        },

      ],
      showAdd: false,
      showEdit: false,
      showDelete: false,
      rows: [],
      searchTerm: '',

    }
  },
  computed: {
    ...mapState('affiliate', ["affiliates", "affiliateSimple", "submitLoading", "submitError"]),
    ...mapState('affiliateFinance', ["finance", "totalCount", "summary", "financeError", "financeLoading"]),
    direction() {
      if (store.state.appConfig.isRTL) {
        this.dir = true
        return this.dir
      }
      this.dir = false
      return this.dir
    },
  },
  watch: {
    finance(newValue, oldValue) {
      if (newValue) {
        this.rows = this.finance
      }
    }
  },
  created() {
    this.$store.dispatch('affiliate/fetchAffiliateList')
    this.$store.dispatch('affiliate/fetchAffiliateListSimple')
    this.user_id = 'all'
    this.$store.dispatch('affiliateFinance/fetchFinanceByAffiliate', {user_id: 'all', count: this.pageLength, page: 1})
    // this.$store.dispatch('business/fetchBusinessSimple')
    this.rows = this.finance
  },
  methods: {
    round,
    updateProduct() {
      console.log(this.user_id)
      this.$store.dispatch('affiliateFinance/fetchFinanceByAffiliate', {
        user_id: this.user_id,
        count: this.pageLength,
        page: 1
      })
    },

    resetTable() {
      this.user_id='all'
      this.$store.dispatch('affiliateFinance/fetchFinanceByAffiliate', {
        user_id: this.user_id,
        count: this.pageLength,
        page: 1
      })
    },

    handleChangePage(page) {
      console.log(page)
      this.pageNumber = page

      this.$store.dispatch('affiliateFinance/fetchFinanceByAffiliate', {
        user_id: this.user_id,
        count: this.pageLength,
        page: page
      })


    },
    handlePageChange(active) {
      this.log.push(`the user change page:  ${active}`)
    },

  }
}
</script>

<style lang="scss">
.no-outline {
  border: none !important;
}

.f20 {
  font-size: 15px !important;
}

@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';

</style>


